<template>
    <div>
      <div class="row domains-brandingdomains-page">
        <div class="col-lg-12">
          <KTPortlet v-bind:title="$t('BRANDING.DOMAIN_LEVEL')">
            <template v-slot:body>
              <div class="row table-filter">
                <div class="col-sm-6">
                  <b-form-group
                  :label="$t('COMMON.SEL_DOMAIN')"
                  label-for="select-domain"
                  >
                    <model-list-select :list="selectDomain.allDomains"
                      v-model="selectDomain.selectedDomain"
                      option-value="domain"
                      option-text="domain"
                      @input="onChangeDomain"
                      :placeholder="$t('COMMON.SEL_DOMAIN')">
                    </model-list-select>
                  </b-form-group>
                  <p>{{ $t("BRANDING.FORM_DESC_DOMAINS") }}</p>
                </div>
              </div>
              <div>
                <b-row class="my-2">
                  <b-col lg="3" class="setting-form">
                      <label for="brandname">{{ $t("BRANDING.COMPANY_NAME") }}</label>
                  </b-col>
                  <b-col lg="9" xl="4">
                      <b-form-input
                      id="brandname"
                      v-model="$v.settings.brandname.$model"
                      :state="validateState('brandname')"
                      :placeholder="$t('BRANDING.COMPANY_NAME')"
                      :disabled="canAdd == false"
                      aria-describedby="input-brandname-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-brandname-feedback">
                      {{ $t("VALIDATION.REQUIRED_FIELD") }}
                      </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row class="my-2">
                  <b-col lg="3" class="setting-form">
                      <label for="supportemail">{{
                      $t("BRANDING.SUPPORT_EMAIL")
                      }}</label>
                  </b-col>
                  <b-col lg="9" xl="4">
                      <b-form-input
                      id="supportemail"
                      v-model="$v.settings.supportemail.$model"
                      :state="validateState('supportemail')"
                      :placeholder="$t('BRANDING.SUPPORT_EMAIL')"
                      :disabled="canAdd == false"
                      aria-describedby="input-supportemail-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-supportemail-feedback">
                      {{ $t("VALIDATION.REQUIRED_FIELD") }}
                      </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row class="my-2">
                  <b-col lg="3" class="setting-form">
                      <label>{{ $t("BRANDING.COMPANY_COLOR") }}</label>
                  </b-col>
                  <b-col lg="9" xl="4">
                      <v-color-picker
                      v-model="$v.settings.brandcolor.$model"
                      mode="hexa"
                      dot-size="25"
                      ></v-color-picker>
                  </b-col>
                </b-row>
                <b-row class="my-2">
                  <b-col lg="3" class="setting-form">
                      <label for="basic_logo">
                      {{ $t("BRANDING.DEFAULT_LOGO_DESC") }} <br />
                      [GIF 210x35]
                      </label>
                  </b-col>
                  <b-col lg="9" xl="5">
                      <b-input-group>
                      <b-form-input
                          id="basic_logo"
                          v-model="$v.settings.basic_logo.$model"
                          :state="validateState('basic_logo')"
                          :placeholder="$t('BRANDING.DEFAULT_LOGO')"
                          :disabled="canAdd == false"
                          aria-describedby="input-basic_logo-feedback"
                      ></b-form-input>
                      <b-input-group-append>
                          <b-button variant="outline-success" @click="previewLogo">
                          {{ $t("BRANDING.PREVIEW") }}
                          </b-button>
                      </b-input-group-append>
                      <b-form-invalid-feedback id="input-basic_logo-feedback">
                          {{ $t("VALIDATION.REQUIRED_FIELD") }}
                      </b-form-invalid-feedback>
                      </b-input-group>
                  </b-col>
                </b-row>
                </div>
                <!-- ----------- preview logo modal -->
                <b-modal
                id="previewModal"
                :title="$t('BRANDING.PREVIEW_LOGO')"
                ref="previewModal"
                hide-footer
                :body-bg-variant="bodyBgVariant"
                >
                <b-img
                    :src="settings.basic_logo"
                    alt="Fluid-grow image"
                    class="objectFit-none"
                ></b-img>
                </b-modal>
                <!-- ---------- preview logo modal end------------- -->
            </template>
            <template v-slot:foot>
                <b-button
                variant="primary"
                @click="updateSetting()"
                class="mr-4"
                >
                {{ $t("BRANDING.UPDATE_SETTINGS") }}
                </b-button>
                <b-button
                variant="secondary"
                @click="resetSetting()"
                class="mr-4"
                >
                {{ $t("BRANDING.RESET") }}
                </b-button>
            </template>
          </KTPortlet>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import { validationMixin } from "vuelidate";
  import KTPortlet from "@/views/partials/content/Portlet.vue";
  import { postRequest } from "@/api/request.js";
  import { required } from "vuelidate/lib/validators";
  
  export default {
    mixins: [validationMixin],
    name: "brandingdomains",
    components: {
      KTPortlet,
    },
    data: () => ({
      selectDomain: {
        allDomains: [],
        selectedDomain: {},
      },
      settings: {
        brandname: "",
        brandcolor: "",
        supportemail: "",
        basic_logo: "",
      },
      bodyBgVariant: "info",
    }),
    computed: {
      canAdd: function () {
        return true;//this.hasPermission("BRANDING", 5);
      },
    },
    created() {
      postRequest({
        action: "getAllDomains",
        token: localStorage.id_token,
      }).then((res) => {
        if (res) {
          if (!res.returncode) {
            res.returndata = [{ domain: res.returndata }];
          }
          this.selectDomain.allDomains = res.returndata;
          this.selectDomain.selectedDomain = {};
        }
      });
      postRequest({
        action: "getAdminBrandInfo",
        token: localStorage.id_token,
      }).then((res) => {
        if (res.returncode) {
          this.settings.brandname = res.returndata.brandname;
          this.settings.brandcolor = '#'+res.returndata.brandcolor;
          this.settings.supportemail = res.returndata.supportemail;
          this.settings.basic_logo = res.returndata.basic_logo;
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    validations: {
      settings: {
        brandname: {
          required,
        },
        brandcolor: {
        },
        supportemail: {
          required,
        },
        basic_logo: {
        },
      },
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t("MENU.BRANDING") },
      ]);
    },
    methods: {
      /* -----validation---- */
      validateState(name) {
        const { $dirty, $error } = this.$v.settings[name];
        return $dirty ? !$error : null;
      },
      /* ---- change domain dropdown ---- */
      onChangeDomain(newValue) {
        this.getDomainBrandingInfo(newValue.domain);
      },
      /* ---------read item---------- */
      getDomainBrandingInfo(domain) {
        postRequest({
          action: "getDomainBrandInfo",
          token: localStorage.id_token,
          domain: domain,
        }).then((res) => {
          if (res.returncode) {
            this.settings.brandname = res.returndata.brandname;
            this.settings.brandcolor = '#'+res.returndata.brandcolor;
            this.settings.supportemail = res.returndata.supportemail;
            this.settings.basic_logo = res.returndata.basic_logo;
          } else {
            this.makeToastVariant("danger", res.returndata);
          }
        });
      },
      /* ---------- preview modal ---------- */
      previewLogo() {
        this.$refs["previewModal"].show();
      },
  
      /* ---------- update setting ---------- */
      updateSetting() {
        this.$v.settings.$touch();
        if (this.$v.settings.$anyError) {
          return;
        }
        
        let brand = {
          'brandname'   : this.settings.brandname,
          'brandcolor'  : this.settings.brandcolor.substr(1, 6),
          'supportemail': this.settings.supportemail,
          'basic_logo' : this.settings.basic_logo,
        };
        postRequest({
          action: "setDomainBranding",
          token: localStorage.id_token,
          brand: brand,
          domain: this.selectDomain.selectedDomain.domain
        }).then((res) => {
          if (res.returncode) {
            this.makeToastVariant("success", res.returndata);
          } else {
            this.makeToastVariant("danger", res.returndata);
          }
        });
      },

      /* ---------- update setting ---------- */
      resetSetting() { 
        const selectedDomain = this.selectDomain.selectedDomain.domain;       
        postRequest({
          action: "resetDomainBranding",
          token: localStorage.id_token,
          domain: selectedDomain
        }).then((res) => {
          if (res.returncode) {
            this.makeToastVariant("success", res.returndata);
            this.getDomainBrandingInfo(selectedDomain);
          } else {
            this.makeToastVariant("danger", res.returndata);
          }
        });
      },

      /* ----notify---- */
      makeToastVariant(variant = null, msg) {
        let title = this.$t("COMMON.ERROR");
        if (variant == "success") {
          title = this.$t("COMMON.SUCCESS");
        }
        this.$bvToast.toast(msg, {
          title: title,
          variant: variant,
          toaster: "b-toaster-top-center",
          // autoHideDelay: 5000,
          solid: true,
        });
      },
    },
  };
  </script>
  